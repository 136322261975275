
import React, {useContext} from 'react'
import {NavBarButton, PageContext, PageTemplate } from 'athena-next-ui-lib'
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {withRouter} from "next/router";



function Custom404 (props) {
    const { router} = useContext(PageContext);


    const content = <div className={"page-not-found"}>404 - Page Not Found</div>;

    const navBarContent = <NavBarButton icon={faArrowLeft} iconSize={"lg"} label={"Back"} action={() => router.back()}/>;



    return  <PageTemplate
        title={"Not Found"}
        pageBar={null}
        navBar={navBarContent}
        pageContent={content}
    />;

}

export default withRouter(Custom404);

